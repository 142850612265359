import React, {useEffect, useState, useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Paper, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';
import InboxIcon from '@material-ui/icons/Inbox';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import {Fragment} from 'react';
import DenyModal from '../components/DenyModal';

const styles = (theme) => ({
  // Search
  'searchPaper': {
    padding: theme.spacing(4),
  },
  'searchTitleWrapper': {
    textAlign: 'center',
    paddingBottom: theme.spacing(6),
  },
  'searchTitle': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  'menuButton': {
    marginRight: theme.spacing(2),
  },
  'formControl': {
    width: '100%',
  },
  'options': {
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  'buttonPrimary': {
    fontWeight: 'bold',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.white + '!important',
    borderRadius: 0 + '!important',
    border: '1px solid #002C47 !important',
		'&:hover': {
			backgroundColor: theme.palette.primary.white + '!important',
			color: theme.palette.primary.main + '!important',
		},
  },
  'buttonSecondary': {
    fontWeight: 'bold',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.main,
    border: '1px solid #002C47',
    borderRadius: 0,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.white,
			border: '1px solid #002C47',
		},
  },
  'buttonIcon': {
    marginLeft: theme.spacing(1),
  },
  'searchInput': {
    width: '75%',
  },
  'searchFilter': {
    fontWeight: 'bold',
    width: '25%',
  },
  'searchSelect': {
    fontWeight: 'bold',
  },
  'loader': {
    color: '#003873',
    fontSize: theme.spacing(5),
    textIndent: '-9999em',
    overflow: 'hidden',
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    position: 'relative',
    transform: 'translateZ(0)',
    animation: '$load 1.7s infinite ease, $round 1.7s infinite ease',
    margin: theme.spacing(3),
  },

  '@keyframes load': {
    '0%': {
      boxShadow: '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
    // 5%,
    '95%': {
      boxShadow: '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
    // 10%,
    '59%': {
      boxShadow: '0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em',
    },
    '20%': {
      boxShadow: '0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em',
    },
    '38%': {
      boxShadow: '0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em',
    },
    '100%': {
      boxShadow: '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
  },
  '@keyframes round': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },

  // Result
  'icon': {
    fontSize: theme.spacing(6),
  },
  'result': {
    margin: '50px 0',
  },
  'noResult': {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
  },
  'tableHeader': {
    padding: '16px 8px',
  },
  'tableSort': {
    fontWeight: 'bold',
  },
  'advertisementRow': {
    cursor: 'pointer',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  'advertisementDeniedRow': {
    cursor: 'pointer',
  },
  'product': {
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'none',
    padding: '16px 8px',
  },
  'tableImage': {
    maxWidth: '200px',
    maxHeight: '100px',
    marginRight: '10px',
  },
  'statusDenied': {
    color: '#E60907',
    borderBottom: 'none',
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
  },
  'statusPending': {
    color: '#F5A000',
    borderBottom: 'none',
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
  },
  'statusApproved': {
    color: '#37AA33',
    borderBottom: 'none',
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
  },
  'advertisementTableCell': {
    borderBottom: 'none',
    padding: '16px 8px',
    fontSize: theme.spacing(2),
  },
  'deniedReason': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: 'red',
  },
  'alignWarningSymbol': {
    marginRight: theme.spacing(1),
  },

  // Tabs
  'tab': {
    textTransform: 'none',
    fontWeight: 'bold',
    borderBottom: '3px solid transparent',
  },
  'activeStatus': {
    textTransform: 'none',
    color: '#00C1DE',
    borderBottom: '3px solid #00C1DE',
    borderRadius: 0,
    fontWeight: 'bold',
  },
  'activeStatusAmount': {
    marginLeft: theme.spacing(2),
  },
  'statusAmount': {
    marginLeft: theme.spacing(2),
    color: '#AACAD0',
  },
  'tabRight': {
    textTransform: 'none',
    float: 'right',
    fontWeight: 'bold',
    borderBottom: '3px solid transparent',
  },
  'tabRightActive': {
    textTransform: 'none',
    float: 'right',
    fontWeight: 'bold',
    borderBottom: '3px solid #00C1DE',
    borderRadius: 0,
    color: '#00C1DE',
  },

  // Export
  'exportTab': {
    padding: '50px 70px'
  },
  'exportTabQuestion': {
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  'exportBlock': {
    border: '1px solid #ACACAC',
    textAlign: 'center',
    padding: theme.spacing(2.5),
    fontWeight: 'bold',
    position: 'relative',
    marginBottom: theme.spacing(6),
		'&:hover': {
			cursor: 'pointer',
		},
  },
  'exportBlockSelected': {
    border: '1px solid #ACACAC',
    textAlign: 'center',
    padding: theme.spacing(2.5),
    fontWeight: 'bold',
    position: 'relative',
    marginBottom: theme.spacing(6),
    backgroundColor: '#EBF2F2',
		'&:hover': {
			cursor: 'pointer',
		},
  },
  'exportCheckbox': {
    top: '-13px',
    right: '-13px',
    position: 'absolute',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.white,
    borderRadius: '50%',
  },
  'exportButton': {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    borderRadius: 0,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    border: '1px solid #002C47',
    float: 'right',
		'&:hover': {
			backgroundColor: theme.palette.primary.white,
			color: theme.palette.primary.main,
		},
  },
  'exportLoader': {
    color: 'white',
    fontSize: theme.spacing(2),
    textIndent: '-9999em',
    overflow: 'hidden',
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    position: 'relative',
    transform: 'translateZ(0)',
    animation: '$load 1.7s infinite ease, $round 1.7s infinite ease',
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
		'&:hover': {
			color: '#003873',
		},
  },

  // Modal
  'modalContainer': {
    zIndex: '10 !important',
  },
  'modalStyle': {
    backgroundColor: theme.palette.primary.white,
    position: 'relative',
    width: '80%',
    left: '10%',
    marginTop: '5%',
    paddingBottom: theme.spacing(10),
    outline: 'none',
    height: '80vh',
  },
  'titleWrapper': {
    padding: '32px 32px 16px',
  },
  'modalTitle': {
    marginBottom: theme.spacing(4),
    display: 'inline-block',
    marginRight: theme.spacing(2.5),
  },
  'modalClose': {
    float: 'right',
    cursor: 'pointer',
    color: '#C7C7C7',
  },
  'modalContent': {
    overflow: 'auto',
    height: '90%',
    padding: '16px 32px 32px',
  },
  'imageWrapper': {
    maxHeight: '600px',
    display: 'flex',
  },
  'mainImage': {
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  'modalImagesRight': {
    height: '100%',
    position: 'relative',
    top: '-8px',
  },
  'modalImage': {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative',
    top: '25%',
  },
  'usp': {
    display: 'flex',
    alignItems: 'center',
  },
  'modalCheck': {
    color: '#00C1DE',
    marginRight: theme.spacing(1),
  },
  'modalDetails': {
    marginTop: theme.spacing(6),
  },
  'modalDetailsHeader': {
    color: 'gray',
    textTransform: 'uppercase',
  },
  'modalDetailsWrapper': {
    height: '100%',
    borderBottom: '1px solid lightgray',
  },
  'modalDetailsItem': {
    fontSize: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: 0,
  },
  'fuelConsumption': {
    textAlign: 'center',
    marginBottom: 0,
  },
  'centerAlign': {
    textAlign: 'center',
    marginTop: theme.spacing(6),
  },
  'approveButton': {
    fontWeight: 'bold',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    borderRadius: 0,
    border: '1px solid #002C47',
    marginRight: theme.spacing(2.5),
    position: 'relative',
    top: theme.spacing(-1),
		'&:hover': {
			backgroundColor: theme.palette.primary.white,
			color: theme.palette.primary.main,
		},
  },
  'denyButton': {
    fontWeight: 'bold',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.main,
    borderRadius: 0,
    border: '1px solid #002C47',
    position: 'relative',
    top: theme.spacing(-1),
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.white,
		},
  },
});

const Overview = ({classes}) => {
  // oems used for the search function
  const oems = [
    {id: 1, brand: 'Audi New', value: 'audi-new'},
    {id: 2, brand: 'Audi Used', value: 'audi'},
    {id: 8, brand: 'Audi Import', value: 'audi-import'},
    {id: 3, brand: 'Das Welt Auto', value: 'daswelt'},
    {id: 4, brand: 'SEAT New', value: 'seat-new'},
    {id: 5, brand: 'SKODA New', value: 'skoda-new'},
    {id: 6, brand: 'Volkswagen New', value: 'vw-new'},
    {id: 7, brand: 'VW Bedrijfswagens New', value: 'bwg-new'},
  ];

  // Advanced search options
  const filters = [
    {id: 1, option: 'Advertisement ID', value: 'ids'},
    {id: 2, option: 'Dealer Number', value: 'dealerDNRs'},
    {id: 3, option: 'Establishment number', value: 'dealerVNRs'},
    {id: 4, option: 'KOM number', value: 'komNumbers'},
    {id: 5, option: 'License plate', value: 'licensePlates'},
    {id: 6, option: 'Model name', value: 'modelNames'},
  ];

  // Table headers
  const columns = [
    {id: 'ids', name: 'Advertisement ID'},
    {id: 'modelName', name: 'Product'},
    {id: 'komNumber', name: 'KOM'},
    {id: 'licensePlate', name: 'License Plate'},
    {id: 'dealerVNR', name: 'Establishment'},
    {id: 'dealerDNR', name: 'Dealer'},
    {id: 'status', name: 'Status'},
  ];

  // Status tabs
  const [status, setStatus] = useState([
    {value: 'all', label: 'All', active: true, amount: 0},
    {value: 'pending', label: 'Pending', active: false, amount: 0},
    {value: 'approved', label: 'Approved', active: false, amount: 0},
    {value: 'denied', label: 'Denied', active: false, amount: 0},
    {value: 'auto-denied', label: 'Auto denied', active: false, amount: 0},
  ]);

  // Export Status
  const [exportStatus, setExportStatus] = useState([
    {value: 'pending', label: 'Pending', export: false},
    {value: 'approved', label: 'Approved', export: false},
    {value: 'denied', label: 'Denied', export: false},
    {value: 'auto-denied', label: 'Auto-denied', export: false},
  ]);

  // Export brands
  const [exportBrand, setExportBrand] = useState([
    {brand: 'Audi Used', value: 'audi', image: '/images/audi.png', export: false},
    {brand: 'Volkswagen New', value: 'vw-new', image: '/images/volkswagen.png', export: false},
    {brand: 'SEAT New', value: 'seat-new', image: '/images/seat_logo.png', export: false},
    {brand: 'Das Welt Auto', value: 'daswelt', image: '/images/das_welt_auto.jpg', export: false},
    {brand: 'SKODA New', value: 'skoda-new', image: '/images/skoda.png', export: false},
    {brand: 'VW Bedrijfswagens New', value: 'bwg-new', image: '/images/vw_bedrijfswagens.png', export: false},
    {brand: 'Audi New', value: 'audi-new', image: '/images/audi.png', export: false},
    {brand: 'Audi Import', value: 'audi-import', image: '/images/audi.png', export: false},
  ]);

  // Default oem in the search function
  const [selectedOem, setOem] = useState('audi-new');
  // Default advanced search option
  const [selectedOption, setOption] = useState('ids');
  // Advanced search option text
  const [advancedSearch, setAdvancedSearch] = useState('');
  // Amount of rows in the table per page
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Current page
  const [page, setPage] = useState(0);
  // Table order
  const [order, setOrder] = useState('asc');
  // Default order by
  const [orderBy, setOrderBy] = useState('ids');
  // Export tab active or not
  const [exportTabActive, setExportTabActive] = useState(false);
  // Advertisement modal active or not
  const [openModal, setOpenModal] = useState(false);
  // Advertisement modal data
  const [modalData, setModalData] = useState(null);
  // First image in the advertisement modal
  const [firstModalImage, setFirstModalImage] = useState('');
  // Extra images in the advertisement modal
  const [extraModalImages, setExtraModalImages] = useState([]);
  // Which image to show big
  const [selectedImage, setSelectedImage] = useState('');
  // Advertisements data
  const [advertisements, setAdvertisements] = useState([]);
  // Loader waiting for the api response
  const [loading, setLoading] = useState(false);
  // Loader waiting for the export
  const [exportLoading, setExportLoading] = useState(false);
  // Total amount advertisements
  const [totalCount, setTotalCount] = useState(0);
  // Deny modal active or not
  const [denyModal, setDenyModal] = useState(false);
  // What advertisement id to deny
  const [denyModalId, setDenyModalId] = useState('');
  // Check if it's the first time loading or not
  const initialRender = useRef(true);

  const denyModalControls = {denyModal, setDenyModal};
  const denyModalIdControls = {denyModalId, setDenyModalId};

  // Change oem in search fucntion
  const handleOemChange = (event) => {
    setOem(event.target.value);
  };

  // Change advanced search option
  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  // Change advanced search text
  const handleSearchChange = (event) => {
    setAdvancedSearch(event.target.value);
  };

  // Change the amount of table rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Change the page number
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // // Get advertisements everytime you change the page or rows per page
  useEffect(() => {
    if (!initialRender.current) {
      searchAdvertisements();
    } else {
      initialRender.current = false;
      return;
    }
    // Disable eslint because searchAdvertisements() is an dependency
    // eslint-disable-next-line
  }, [page, rowsPerPage, status, order, orderBy]);

  // Change order of table
  const createSortHandler = (event, newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(newOrderBy);
  };

  // Change order column
  const handleChangeOrderBy = (property) => (event) => {
    createSortHandler(event, property);
  };

  // Use new search values
  function newSearch() {
    if (page === 0) {
      searchAdvertisements();
    } else {
      setPage(0);
    }
  }

  // Api call to get all advertisements of the selected oem
  // Also use advanced search when a value is filled in
  function searchAdvertisements() {
    setLoading(true);
    let activeTab = ['approved', 'denied', 'pending', 'auto-denied'];
    let chosenOrder = false;

    status.forEach(function(s) {
      if (s.active === true && s.value !== 'all') {
        activeTab = [s.value];
      }
    });

    if ( order === 'asc' ) {
      chosenOrder = true;
    } else {
      chosenOrder = false;
    }

    // Check if we got a value in the advanced search field
    if (advancedSearch !== '') {
      fetch(
          `${process.env.REACT_APP_API_URL}/sites/${selectedOem}/advertisements/search`, {
            headers: {
              'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`)}`,
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
              filters: {
                [selectedOption]: [advancedSearch],
                statuses: activeTab,
              },
              page: page,
              pageSize: rowsPerPage,
              sorting: {
                byField: orderBy,
                isAscending: chosenOrder,
              },
            }),
          }).then((response) => response.json())
          .then((data) => {
            setAdvertisements(data.results);
            setTotalCount(data.totalCount);
            status.forEach(function(s) {
              switch ( s.value ) {
                case 'approved':
                  s.amount = data.statuses.approved;
                  break;
                case 'pending':
                  s.amount = data.statuses.pending;
                  break;
                case 'denied':
                  s.amount = data.statuses.denied;
                  break;
                case 'auto-denied':
                  s.amount = data.statuses['auto-denied'];
                  break;
                default:
                  if (s.active === true) {
                    s.amount = data.totalCount;
                  } else {
                    s.amount = data.statuses.approved +
                    data.statuses.pending +
                    data.statuses.denied +
                    data.statuses['auto-denied'];
                  }
                  break;
              }
            });
            setLoading(false);
          });
    } else {
      fetch(
          `${process.env.REACT_APP_API_URL}/sites/${selectedOem}/advertisements/search`, {
            headers: new Headers({
              "Authorization": `Basic ${btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`)}`,
              'Content-Type': 'application/json',
            }),
            method: 'POST',
            body: JSON.stringify({
              "filters": {
                "statuses": activeTab,
              },
              "page": page,
              "pageSize": rowsPerPage,
              "sorting": {
                "byField": orderBy,
                "isAscending": chosenOrder,
              },
            }),
          }).then((response) => response.json())
          .then((data) => {
            setAdvertisements(data.results);
            setTotalCount(data.totalCount);
            status.forEach(function(s) {
              switch(s.value) {
                case 'approved':
                  s.amount = data.statuses.approved;
                  break;
                case 'pending':
                  s.amount = data.statuses.pending;
                  break;
                case 'denied':
                  s.amount = data.statuses.denied;
                  break;
                case 'auto-denied':
                  s.amount = data.statuses['auto-denied'];
                  break;
                default:
                  if (s.active === true) {
                    s.amount = data.totalCount;
                  } else {
                    s.amount = data.statuses.approved + data.statuses.pending + data.statuses.denied + data.statuses['auto-denied'];
                  }
                  break;
              }
            });
          setLoading(false);
        });
    }
  }

  // Show all advertisements by status
  function advertisementByStatus(e, tab) {
    setExportTabActive(false);

    const newStatus = [];
    status.forEach(function(s) {
      if(s.value === tab) {
        s.active = true;
      } else {
        s.active = false;
      }
      newStatus.push(s);
    });
    setStatus(newStatus);
    setPage(0);
  }

  // Open the export tab
  function openExportTab() {
    setExportTabActive(true);

    const newStatus = [];
    status.forEach(function(s) {
      s.active = false;
      newStatus.push(s);
    });
    setStatus(newStatus);
  }

  // What statusses to export
  function changeExportStatusData(e, value, exportValue) {
    const newExportStatus = [];
    exportStatus.forEach(function(es) {
      if(es.value === value) {
        if(exportValue === false) {
          es.export = true;
        } else {
          es.export = false;
        }
      }
      newExportStatus.push(es);
    });

    setExportStatus(newExportStatus);
  }

  // What brands to export
  function changeExportBrandData(e, value, exportValue) {
    const newExportBrand = [];
    exportBrand.forEach(function(eb) {
      if(eb.value === value) {
        if(exportValue === false) {
          eb.export = true;
        } else {
          eb.export = false;
        }
      }
      newExportBrand.push(eb);
    });

    setExportBrand(newExportBrand);
  }

  // Export advertisement based on selected statusses and brands
  function exportAdvertisements(e) {
    const brands = [];
    const statuses = [];
    const headers = [];
    const exportCSV = [];
    let tempExportRow = [];
    setExportLoading(true);

    // Get brands to export
    exportBrand.forEach(function(b) {
      if(b.export === true) {
        brands.push(b.value);
      }
    });

    // Get statusses to export
    exportStatus.forEach(function(s) {
      if(s.export === true) {
        statuses.push(s.value);
      }
    });

    fetch(
      `${process.env.REACT_APP_API_URL}/advertisements/search`, {
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`)}`,
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          "filters": {
            "statuses": statuses,
            "sites": brands,
          },
        })
      }).then((response) => response.json())
        .then((data) => {
          // Set csv header row
          headers.push(
            'Advertisement create date',
            'Last update date',
            'Dealernummer',
            'Vestigingsnummer',
            'Advertisement ID',
            'Advertisement status',
            'Merk',
            'Model',
            'Uitvoering',
            'Kenteken',
            'Komnummer',
            'Chassisnummer',
            'Reguliere prijs',
            'Actieprijs',
            'Private lease prijs',
            'Brandstof',
            'Transmissie',
            'Carrosserie',
            'Motorinhoud',
            'Gewicht',
            'Energielabel',
            'Gemiddeld verbruik',
            'Video URL'
          );
          exportCSV.push(headers);

          data.results.forEach((ad) => {
            // Change multiple fuelTypes to a string
            let fuelType = '';
            ad.fuelTypes.forEach(function(ft, index) {
              fuelType += ft;
              if(index+1 !== ad.fuelTypes.length) {
                fuelType += '/';
              }
            });

            let videos = '';
            ad.videos.forEach(function(video, index) {
              videos += video.externalUrl;
              if (index + 1 !== ad.videos.length) {
                videos += ', ';
              }
            });

            // Format created and lastUpdated date
            let created = '';
            let updated = '';
            created = formatDateAndTime(ad.created);
            updated = formatDateAndTime(ad.lastUpdated);

            // Set csv row
            tempExportRow = [];
            tempExportRow.push(
              created,
              updated,
              ad.dealerDNR,
              ad.dealerVNR,
              ad.id,
              ad.status,
              ad.brandName,
              ad.modelName,
              ad.version,
              ad.licensePlate,
              ad.komNumber,
              ad.vinNumber,
              ad.priceB2C,
              ad.priceSale,
              ad.leasePrice,
              fuelType,
              ad.transmissionType,
              ad.bodyType,
              ad.engineSize,
              ad.weight,
              ad.energyLabel,
              ad.averageFuelConsumption,
              videos
            );
            exportCSV.push(tempExportRow);
          });

          let csv = '';
          exportCSV.forEach(function(row) {
            csv += row.join(';');
            csv += '\n';
          });

          const hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          hiddenElement.target = '_blank';
          hiddenElement.download = 'export.csv';
          hiddenElement.click();
          setExportLoading(false);
      }
    );
  }

  // Open advertisement modal
  function openAdvertisementDetails(e, advertisementId) {
    fetch(
      `${process.env.REACT_APP_API_URL}/sites/${selectedOem}/advertisements/${advertisementId}`, {
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`)}`
        }),
      }).then((response) => response.json())
        .then((data) => {
        // Change apkDate format
        const formattedApkDate = formatDate(data.apkDate);
        data.apkDate = formattedApkDate;

        // Change dateFirstAdmission format
        const formattedDateFirstAdmission = formatDate(data.dateFirstAdmission);
        data.dateFirstAdmission = formattedDateFirstAdmission;

        const images = data.images;
        setModalImages(images);

        setModalData(data);

        setOpenModal(true);
      }
    );
  }

  // Date format
  function formatDate(date) {
    const newDate = new Date(date);
    let day = newDate.getDate();
    if(day < 10) {
      day = '0' + day;
    }

    let month = newDate.getMonth() + 1;
    if(month < 10) {
      month = '0' + month;
    }
    const formattedDate = day + '-' + month + '-' + newDate.getFullYear();

    return formattedDate;
  }

  // Date format with time
  function formatDateAndTime(date) {
    const newDate = new Date(date);
    let day = newDate.getDate();
    if(day < 10) {
      day = '0' + day;
    }

    let month = newDate.getMonth() + 1;
    if(month < 10) {
      month = '0' + month;
    }
    let hours = newDate.getHours();
    if(hours < 10) {
      hours = '0' + hours;
    }
    let minutes = newDate.getMinutes();
    if(minutes < 10) {
      minutes = '0' + minutes;
    }
    const formattedDateAndTime = day + '-' + month + '-' + newDate.getFullYear() + ' ' + hours + ':' + minutes;

    return formattedDateAndTime;
  }

  // Set the images in the advertisements modal
  function setModalImages(images) {
    setFirstModalImage(images[0]);
    setSelectedImage(images[0]);

    const extraImages = images.slice(1, 5);

    setExtraModalImages(extraImages);
  }

  // Close advertisement modal
  function closeAdvertisementDetails() {
    setOpenModal(false);
    setModalData('');
    searchAdvertisements();
  }

  // Approve chosen advertisement
  function approveAdvertisement(e, advertisementId) {
    fetch(
      `${process.env.REACT_APP_API_URL}/sites/${selectedOem}/advertisements/${advertisementId}`, {
        headers: new Headers({
          "Authorization": `Basic ${btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`)}`,
          'Content-Type': 'application/json',
        }),
        method: 'PATCH',
        body: JSON.stringify({
          status: 'approved',
          statusReason: '',
        }),
      }).then(() => {
        searchAdvertisements();
        closeAdvertisementDetails();
      });
  }

  function openDenyModal(e, advertisementId) {
    setDenyModal(true);
    setDenyModalId(advertisementId);
  }

  // Change big image in advertisement modal
  function changeImage(e, image) {
    setSelectedImage(image);
  }

  return (
      <>
        {/* Search Paper */}
        <Paper className={classes.searchPaper}>
          <Container fixed>

            <Grid container>

                <Grid item
                  md={ 12 }
                  className={ classes.searchTitleWrapper }
                >
                  <Typography
                    variant="h6"
                    className={classes.searchTitle}
                  >
                    Advertisement Management
                  </Typography>
                </Grid>

                {/* Search OEM */}
                <Grid item
                md={ 4 } >
                <Typography
                  variant="h6"
                  className={ classes.options } >
                  Advertisement on:
                </Typography>
              </Grid>
                <Grid item
                md={ 4 } >
                <FormControl className={classes.formControl}>
                  <Select
                    value={selectedOem}
                    onChange={handleOemChange}
                    className={classes.searchSelect}
                  >
                    {oems.map(function(oem) {
                      return <MenuItem key={oem.id} value={oem.value}>{oem.brand}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
                <Grid item
                md={ 4 }
                align="center">
                <Button
                  variant="contained"
                  className={advertisements.length === 0 ? classes.buttonPrimary : classes.buttonSecondary}
                  onClick={newSearch}
                >
                  Find Advertisements
                  <SearchIcon className={classes.buttonIcon}/>
                </Button>
              </Grid>

                {/* Advanced Search */}
                <Grid item
                md={ 8 }
                align="right" >
                <TextField
                  placeholder="Advanced Search"
                  value={advancedSearch}
                  onChange={handleSearchChange}
                  className={classes.searchInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className={classes.searchFilter}
                  >
                    {filters.map(function(filter) {
                      return <MenuItem key={filter.id} value={filter.value}>{filter.option}</MenuItem>;
                    })}
                </Select>
              </Grid>

              </Grid>
          </Container>
        </Paper>

        <Container fixed>
          <div className={ classes.result }>

            {/* Loading */}
            {loading === true &&
              <div className={classes.noResult}>
                <div className={classes.loader}></div>
              </div>
            }

            {/* No Result */}
            {advertisements.length === 0 && loading === false &&
              <div className={classes.noResult}>
                <InboxIcon className={ classes.icon } />
                <Typography
                  variant="h6"
                >
                  No result yet
                </Typography>
                Search your advertisements to get start
              </div>
            }

            {advertisements.length > 0 && loading === false &&
              <>
                {/* Tabs */}
                {status.map(function(button) {
                  return <Button
                    key={button.value}
                    data-value="All"
                    className={button.active === true ? classes.activeStatus : classes.tab}
                    onClick={(e) => advertisementByStatus(e, button.value)}
                  >
                    {button.label}
                    <span
                      className={button.active === true ? classes.activeStatusAmount : classes.statusAmount}
                    >
                      {button.amount}
                    </span>
                  </Button>;
                })}

                {/* Export */}
                <Button
                  onClick={(e) => openExportTab(e)}
                  className={exportTabActive === true ? classes.tabRightActive : classes.tabRight}>
                  Export ads
                </Button>

                {exportTabActive === false &&
                  <>
                    {/* Results table */}
                    <Paper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            { columns.map(function(column) {
                              return <TableCell className={classes.tableHeader} key={column.id} sortDirection={orderBy === column.id ? order : false}>
                                <TableSortLabel
                                  className={classes.tableSort}
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'asc'}
                                  onClick={handleChangeOrderBy(column.id)}>
                                    {column.name}
                                </TableSortLabel>
                              </TableCell>;
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          { advertisements.map(function(advertisement) {
                            let denied = false;
                            if((advertisement.status === 'denied' || advertisement.status === 'auto-denied') && advertisement.denyReason !== '') {
                              denied = true;
                            }
                            return (
                              <Fragment key={advertisement.id}>
                                <TableRow
                                  className={denied === true ? classes.advertisementDeniedRow : classes.advertisementRow}
                                  onClick={(e) => openAdvertisementDetails(e, advertisement.id)}
                                >
                                  <TableCell className={classes.advertisementTableCell}>{advertisement.id}</TableCell>
                                  <TableCell className={classes.advertisementTableCell}>
                                    <div className={classes.product}>
                                      {/* {advertisement.iamges !== [] && */}
                                        <img className={classes.tableImage} src={advertisement.images} alt={advertisement.modelName}/>
                                      {/* } */}
                                      <Typography>
                                        {advertisement.brandName} {advertisement.modelName}
                                      </Typography>
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.advertisementTableCell}>{advertisement.komNumber}</TableCell>
                                  <TableCell className={classes.advertisementTableCell}>{advertisement.licensePlate}</TableCell>
                                  <TableCell className={classes.advertisementTableCell}>{advertisement.dealerVNR}</TableCell>
                                  <TableCell className={classes.advertisementTableCell}>{advertisement.dealerDNR}</TableCell>
                                  {advertisement.status === "denied" &&
                                    <TableCell className={classes.statusDenied}>{advertisement.status}</TableCell>
                                  }
                                  {advertisement.status === "auto-denied" &&
                                    <TableCell className={classes.statusDenied}>{advertisement.status}</TableCell>
                                  }
                                  {advertisement.status === "pending" &&
                                    <TableCell className={classes.statusPending}>{advertisement.status}</TableCell>
                                  }
                                  {advertisement.status === "approved" &&
                                    <TableCell className={classes.statusApproved}>{advertisement.status}</TableCell>
                                  }
                                </TableRow>
                                {denied === true &&
                                  <TableRow
                                    className={classes.advertisementRow}
                                    onClick={(e) => openAdvertisementDetails(e, advertisement.id)}
                                  >
                                    <TableCell
                                      colSpan={7}
                                      className={classes.advertisementTableCell}
                                    >
                                      <div className={classes.deniedReason}>
                                        <ErrorOutlineIcon className={classes.alignWarningSymbol}/>
                                        {advertisement.statusReason}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                }
                              </Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={ [10, 25, 50, 100] }
                        component="div"
                        count={ totalCount }
                        rowsPerPage={ rowsPerPage }
                        page={ page }
                        onChangePage={ handleChangePage }
                        onChangeRowsPerPage={ handleChangeRowsPerPage }
                      />
                    </Paper>

                    {!!modalData &&
                      // Advertisement details
                      <Modal
                        open={openModal}
                        onClose={closeAdvertisementDetails}
                        className={classes.modalContainer}
                      >
                        <div className={classes.modalStyle}>
                          <Grid
                            container
                            spacing={2}
                            className={classes.titleWrapper}
                          >
                            <Grid
                              item
                              md={10}
                            >
                              <Typography
                                variant="h4"
                                className={classes.modalTitle}
                              >
                                <Box
                                  fontWeight="fontWeightBold"
                                >
                                  {modalData.brandName} {modalData.modelName}
                                </Box>
                              </Typography>
                              <Button
                                className={classes.approveButton}
                                onClick={(e) => approveAdvertisement(e, modalData.id)}
                              >
                                Approve
                              </Button>
                              <Button
                                className={classes.denyButton}
                                onClick={(e) => openDenyModal(e, modalData.id)}
                              >
                                Deny
                              </Button>
                            </Grid>
                            <Grid
                              item
                              md={2}
                            >
                              <CloseIcon
                                className={classes.modalClose}
                                onClick={closeAdvertisementDetails}
                              />
                            </Grid>
                          </Grid>

                          <div className={classes.modalContent}>
                            {/* Images */}
                            <Grid
                              container
                              spacing={2}
                            >
                              <Grid
                                item
                                md={7}
                                className={classes.imageWrapper}
                              >
                                <img className={classes.mainImage} alt={modalData.product} src={selectedImage} width="100%"/>
                              </Grid>

                              <Grid
                                item
                                md={5}
                              >
                                <Grid
                                  container
                                  spacing={2}
                                  className={classes.modalImagesRight}
                                >
                                  {extraModalImages.map(function(image, index) {
                                    return <Grid
                                      item
                                      md={6}
                                      key={index}
                                    >
                                      <img
                                        alt={modalData.product}
                                        src={image}
                                        onMouseEnter={(e) => changeImage(e, image)}
                                        onMouseLeave={(e) => changeImage(e, firstModalImage)}
                                        className={classes.modalImage}
                                      />
                                    </Grid>;
                                  })}
                                </Grid>
                              </Grid>
                            </Grid>

                            {/* Checkmarks */}
                            <Grid
                              container
                              spacing={4}
                            >
                              <Grid
                                item
                                className={classes.usp}
                              >
                                <CheckCircleOutlineIcon className={classes.modalCheck}/>
                                APK tot {modalData.apkDate}
                              </Grid>
                              {modalData.hasMaintenanceGuide === true &&
                                <Grid
                                item
                                className={classes.usp}
                                >
                                  <CheckCircleOutlineIcon className={classes.modalCheck}/>
                                  Onderhoudsboekje aanwezig
                                </Grid>
                              }
                            </Grid>

                            {/* Details */}
                            <Typography
                              variant="h6"
                              className={classes.modalDetails}
                            >
                              <Box
                                fontWeight="fontWeightBold"
                              >
                                Kenmerken
                              </Box>
                            </Typography>
                            <Grid
                            container
                            spacing={2}
                          >
                            {/* Price */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Prijs
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  € {modalData.priceB2C}
                                </p>
                              </div>
                            </Grid>
                            {/* Odometer value */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Kilometerstand
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.odometerValue} km
                                </p>
                              </div>
                            </Grid>
                            {/* Fuel */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Brandstof
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.fuelTypes.map(function(f, index) {
                                    if(modalData.fuelTypes.length !== (index + 1)) {
                                      return `${f}, `;
                                    } else {
                                      return f;
                                    }
                                  })}
                                </p>
                              </div>
                            </Grid>

                            {/* Transmission */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Transmissie
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.transmissionType}
                                </p>
                              </div>
                            </Grid>
                            {/* Version */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Uitvoering
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.version}
                                </p>
                              </div>
                            </Grid>
                            {/* Engine size */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Motorinhoud
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.engineSize} L
                                </p>
                              </div>
                            </Grid>

                            {/* Date first admission */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Eerste toelating
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.dateFirstAdmission}
                                </p>
                              </div>
                            </Grid>
                            {/* VAT deductable */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  BTW verrekenbaar
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.isVATDeductable === true ? 'Ja' : 'Nee'}
                                </p>
                              </div>
                            </Grid>
                            {/* License plate */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Kenteken
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.licensePlate}
                                </p>
                              </div>
                            </Grid>

                            {/* Warranty */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Garantie
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.dealerHasBrandWarrantyLabel === true ? 'Ja' : 'Nee'}
                                </p>
                              </div>
                            </Grid>
                            {/* Body type */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Carrosserie
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.bodyType}
                                </p>
                              </div>
                            </Grid>
                            {/* Color */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Kleur
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.color}
                                </p>
                              </div>
                            </Grid>

                            {/* Weight */}
                            <Grid
                              item
                              md={4}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Gewicht
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.weight} kg
                                </p>
                              </div>
                            </Grid>
                            {/* Fuel consumption */}
                            <Grid
                              item
                              md={2}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Verbruik / 100km
                                </span>
                                <p className={classes.fuelConsumption}>
                                  <LocalGasStationIcon/>
                                </p>
                              </div>
                            </Grid>
                            {/* Fuel consumption city */}
                            <Grid
                              item
                              md={2}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Stad
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.urbanFuelConsumption} L
                                </p>
                              </div>
                            </Grid>
                            {/* Fuel consumption highway */}
                            <Grid
                              item
                              md={2}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Snelweg
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.extraUrbanFuelConsumption} L
                                </p>
                              </div>
                            </Grid>
                            {/* Fuel consumption average */}
                            <Grid
                              item
                              md={2}
                            >
                              <div className={classes.modalDetailsWrapper}>
                                <span className={classes.modalDetailsHeader}>
                                  Gemiddeld
                                </span>
                                <p className={classes.modalDetailsItem}>
                                  {modalData.averageFuelConsumption} L
                                </p>
                              </div>
                            </Grid>

                            {/* Description */}
                            <Grid
                              item
                              md={12}
                            >
                              <p>
                                {modalData.commentsB2C}
                              </p>
                            </Grid>
                          </Grid>

                            {/* Bottom approve/deny buttons */}
                            <div className={classes.centerAlign}>
                              <Button
                                className={classes.approveButton}
                                onClick={(e) => approveAdvertisement(e, modalData.id)}
                              >
                                Approve
                              </Button>
                              <Button
                                className={classes.denyButton}
                                onClick={(e) => openDenyModal(e, modalData.id)}
                              >
                                Deny
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    }
                  </>
                }

                {exportTabActive === true &&
                  // Export tab
                  <Paper>
                    <div className={classes.exportTab}>

                      <Grid container>
                        <Grid item md={10}>
                          <p className={classes.exportTabQuestion}>
                            What kind of of ads do you want to export?
                          </p>
                        </Grid>

                        <Grid item md={2}>
                          <Button
                            className={classes.exportButton}
                            onClick={(e) => exportAdvertisements(e)}
                          >
                            Export
                            {exportLoading !== true &&
                              <PublishIcon className={classes.buttonIcon}/>
                            }
                            {exportLoading === true &&
                              <div className={classes.exportLoader}></div>
                            }
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={4}
                      >
                        {exportStatus.map(function(es) {
                          return <Grid
                            item
                            xs
                            key={es.value}
                          >
                            <div
                              onClick={(e) => changeExportStatusData(e, es.value, es.export)}
                              className={es.export === true ? classes.exportBlockSelected : classes.exportBlock}
                            >

                              <p>{es.label}</p>

                              {es.export === true &&
                                <CheckCircleIcon className={classes.exportCheckbox}/>
                              }

                            </div>
                          </Grid>;
                        })}
                      </Grid>

                      <p className={classes.exportTabQuestion}>
                        Which group of ads do you want to export?
                      </p>
                      <Grid
                        container
                        spacing={4}
                      >
                        {exportBrand.map(function(eb) {
                          return <Grid
                          item
                          md={4}
                          key={eb.value}
                          >
                            <div
                              onClick={(e) => changeExportBrandData(e, eb.value, eb.export)}
                              className={eb.export === true ? classes.exportBlockSelected : classes.exportBlock}
                            >

                              <img alt={eb.label} height="50px" src={eb.image}/>
                              <p>{eb.brand}</p>

                              {eb.export === true &&
                                <CheckCircleIcon
                                  className={classes.exportCheckbox}
                                />
                              }

                            </div>
                          </Grid>;
                        })}
                      </Grid>

                    </div>
                  </Paper>
                }
              </>
            }

            {/* Deny modal */}
            <DenyModal
              { ...denyModalControls }
              { ...denyModalIdControls }
              brand={selectedOem}
            />
          </div>

        </Container>
      </>
  );
};

export default withStyles(styles)(Overview);
